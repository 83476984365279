import React from "react";

import { Switch, Route } from 'react-router-dom';
import Home from '../pages/home.js';
import Cv from '../pages/cv.js';
import samplePDF from "../assets/Files/Jaouher Hammouda CV - PEAK (1).pdf";

const Main = () => {
   return (

    <Switch> {/* The Switch decides which component to show based on the current URL.*/}
      <Route exact path='/' component={Home}></Route>   
      <Route path="/cv" render={(routeProps) => {return <Cv pdf={samplePDF} {...routeProps}/>}} />
    </Switch>  
  );
}

export default Main;
