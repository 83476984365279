import React from "react";
import Header from "../components/header/Header";
import Greeting from "../containers/greeting/Greeting";
import Skills from "../containers/skills/Skills";
import StackProgress from "../containers/skillProgress/skillProgress";
import WorkExperience from "../containers/workExperience/WorkExperience";
import Projects from "../containers/projects/Projects";
import StartupProject from "../containers/StartupProjects/StartupProject";
import Achievement from "../containers/achievement/Achievement";
import Blogs from "../containers/blogs/Blogs";
import Footer from "../components/footer/Footer";
import Talks from "../containers/talks/Talks";
import Podcast from "../containers/podcast/Podcast";
import Education from "../containers/education/Education";
import Top from "../containers/topbutton/Top";
import Twitter from "../containers/twitter-embed/twitter";
import Profile from "../containers/profile/Profile";
import {StyleProvider} from "../contexts/StyleContext";
import {useLocalStorage} from "../hooks/useLocalStorage";
import "../containers/Main.scss";

const Home = () => {
    const darkPref = window.matchMedia("(prefers-color-scheme: dark)");
    const [isDark, setIsDark] = useLocalStorage("isDark", darkPref.matches);
    
    const changeTheme = () => {
      setIsDark(!isDark);
    };
    return (
        <div className={isDark ? "dark-mode" : null}>
        <StyleProvider value={{isDark: isDark, changeTheme: changeTheme}}>
          <Header />
          <Greeting />
          <Skills />
          <StackProgress />
          <Education />
          <WorkExperience />
          <Projects />
          <StartupProject />
          <Achievement />
          <Blogs />
          <Talks />
          <Twitter />
          <Podcast />
          <Profile />
          <Footer />
          <Top />
        </StyleProvider>
      </div>
      
      );
}
export default Home;
